@import "color-palette";
@import "vars";
@import "breakpoints";
@import "zIndexes";

.avatar {
    display: flex;
    justify-content: center;
    gap: 20px;

    &__preview {
        position: relative;
    }

    &__logo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
    }

    &__label {
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-medium;
        color: $color-text-aqua;
        padding: 22px 0;
        cursor: pointer;
    }

    &__delete {
        position: absolute;
        top: -4px;
        left: 40px;
        width: 24px;
        height: 24px;
        border: 0.5px solid $color-border-silver;
        padding: 0;
        background-color: $color-bg-white;
        border-radius: $border-radius-50-percent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &--icon {
            width: 10px;
            height: 10px;
        }
    }

    &__input {
        display: none;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-bg-black-light;
        z-index: $overlayCropper;
    }

    &__modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 600px;
        background-color: $color-bg-white;
        padding: 20px;
        border-radius: $border-radius-8;
        z-index: $modalCropper;
        overflow: hidden;
    }

    &__edit {
        font-size: 18px;
        line-height: 22px;
        font-weight: $font-weight-bold;
        padding: 0;
        text-align: center;
    }

    &__cropper {
        width: 100%;
        height: 400px;
        max-width: 100%;
        margin: 20px 0;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__save,
    &__cancel {
        min-width: 150px;
        padding: 12px 10px;
        border-radius: $border-radius-10;
        border: none;
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-semibold;
        cursor: pointer;
    }

    &__save {
        background-color: $color-bg-blue;
        color: $color-text-white;
    }

    &__cancel {
        border: 1px solid $color-divider-border;
        color: $color-text-black-green;
    }
}